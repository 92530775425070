<template>
  <div>
    <GenericBTable
      :items="matrix2_capacities"
      :fields="fields"
      :filter="input_search"
      :filterCustom="filterCustom"
      :columns_display="false"
      @emitChangeSearchField="changeInputSearch"
    >
      <template #head(selected)>
        <b-checkbox
          v-model="all_selected"
          @input="selectAll"
          v-b-tooltip.v-secondary="
            all_selected ? 'Deseleccionar Todos' : 'Seleccionar Todos'
          "
        ></b-checkbox>
      </template>
      <template #cell(selected)="row">
        <b-checkbox
          v-model="row.item.selected"
          @input="selectCapacity(row.item.id, $event)"
        ></b-checkbox>
      </template>
    </GenericBTable>
    <div class="d-flex">
      <b-button class="btn-cancel" size="sm" @click="$emit('close_modal')">
        Cancelar
      </b-button>
      <b-button size="sm" class="mr-3" @click="save">Guardar</b-button>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "CapacityTable",
  components: {
    GenericBTable: () => import("@/components/reusable/GenericBTable.vue"),
  },
  props: {
    matter: {
      type: Object,
      required: true,
    },
    competence_selected_ids: {
      type: Array,
    },
    matrix2_competences: {
      type: Array,
    },
  },
  data() {
    return {
      input_search: "",
      all_selected: false,
      matrix2_capacities: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      capacities: names.CAPACITIES,
    }),
    egressProfileCapacitiesIds() {
      let capacities = [];
      this.matrix2_competences.forEach((element) => {
        if (this.competence_selected_ids.includes(element.id))
          capacities = capacities.concat(element.capacities);
      });
      return [...new Set(capacities)];
    },
    fields() {
      return [
        {
          key: "selected",
          label: "Seleccionar",
          display_column: true,
          thStyle: { width: "1%" },
        },
        {
          key: "name",
          label: this.$getVisibleNames("mesh.capacity", true, "Capacidades"),
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
        },
      ];
    },
  },
  methods: {
    changeInputSearch(input_search) {
      this.input_search = input_search;
    },
    filterCustom(row) {
      if (this.$filtered(row.name, this.input_search)) return true;
      else return false;
    },
    selectAll(event) {
      this.matrix2_capacities.forEach((matrix2_capacities) => {
        matrix2_capacities.selected = event;
      });
    },
    selectCapacity(capacity_id, event) {
      const index = this.matrix2_capacities.findIndex(
        (x) => x.id == capacity_id
      );
      if (index != -1) {
        this.matrix2_capacities[index].selected = event;
      }
    },
    save() {
      this.$store
        .dispatch(names.PATCH_EGRESS_PROFILE_MATTER, {
          egress_profile_matter_id: this.matter.id,
          item: {
            capacities: this.matrix2_capacities
              .filter((x) => x.selected == true)
              .map((x) => x.id),
          },
        })
        .then(() => {
          toast(
            this.$getVisibleNames("mesh.capacity", true, "Capacidades") +
              " actualizadas."
          );
          this.$emit("close_modal");
        });
    },
  },
  mounted() {
    this.matrix2_capacities = this.capacities
      .filter((x) => this.egressProfileCapacitiesIds.includes(x.id))
      .map((x) => ({ ...x, selected: this.matter.capacities.includes(x.id) }));
  },
  created() {},
};
</script>

<style scoped>
.btn-cancel {
  margin-right: auto;
  margin-left: 1.2em;
}
</style>

